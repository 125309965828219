:root {
  --primary-color: #22a3b9;
  --secondary-color: #244b69;
}

.App {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

table {
  border: 2px solid var(--primary-color);
  width: 100%;
  margin: 20px 0;
  /* height: 200px; */
}

th {
  border-bottom: 2px solid black;
}

td {
  border-top: var(--secondary-color) 1px solid;
}

th,
td {
  border-right: var(--secondary-color) 1px solid;
  padding: 0 5px;
}

.hscroll {
  overflow: auto;
  white-space: nowrap;
  height: 75vh;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
  background-color: var(--secondary-color);
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color);
}

iframe {
  width: auto;
  height: 180px;
}
